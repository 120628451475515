@import "../../imports";

nav {
  display: block;
  position: fixed;
  top: 2rem;
  left: 2rem;
  z-index: 50;

  .navigation {
    @include flex($direction: row);

    .nav_button {
      @include width-height($width: 3rem, $height: 3.5rem);
      @include transform(rotate(0deg));
      @include transition($time-in: 0.5s);
      cursor: pointer;

      &:hover span {
        @include hoverBrighten();
      }

      span {
        @include width-height($width: 50%, $height: 0.25rem);
        display: block;
        position: absolute;
        background-color: $peru;
        opacity: 1;
        @include transform(rotate(0deg));
        @include transition($time-in: 0.25s);

        &:nth-child(even) {
          left: 50%;
          border-radius: 0 0.25rem 0.25rem 0;
        }

        &:nth-child(odd) {
          left: 0;
          border-radius: 0.25rem 0 0 0.25rem;
        }

        &:nth-child(1),
        &:nth-child(2) {
          top: 0;
        }
        &:nth-child(3),
        &:nth-child(4) {
          top: 0.7rem;
        }
        &:nth-child(5),
        &:nth-child(6) {
          top: 1.4rem;
        }
      }

      &.open {
        ~ .nav_menu {
          left: 5rem;
        }

        span {
          &:nth-child(1),
          &:nth-child(6) {
            @include transform(rotate(45deg));
          }
          &:nth-child(2),
          &:nth-child(5) {
            @include transform(rotate(-45deg));
          }
          &:nth-child(1) {
            left: 5px;
            top: 7px;
          }
          &:nth-child(2) {
            left: calc(50% - 5px);
            top: 7px;
          }
          &:nth-child(3) {
            left: -50%;
            opacity: 0;
          }
          &:nth-child(4) {
            left: 100%;
            opacity: 0;
          }
          &:nth-child(5) {
            left: 5px;
            top: 22px;
          }
          &:nth-child(6) {
            left: calc(50% - 5px);
            opacity: 29px;
          }
        }
      }
    }

    .nav_menu {
      @include flex($direction: row);
      @include transition($for: opacity, $time-in: 0.2s, $time-out: 0.2s);
      @include box-shadow(0px, 0px, 16px, rgba(0, 0, 0, 0.6));
      width: 250px;
      position: absolute;
      left: 4rem;
      padding: 0.25rem 0;
      opacity: 0;
      backdrop-filter: blur(25px);

      .link_button {
        max-width: 60px;
        width: 60px;
      }
    }

    &.open .nav_menu {
      opacity: 1;

      .link_button {
        a {
          &:before {
            margin-top: 1.4rem;
            margin-left: 0;
          }
        }

        &:first-child a:before {
          width: 48px;
        }

        &:nth-child(2) a:before {
          width: 47px;
        }

        &:nth-child(3) a:before {
          width: 40px;
        }

        &:nth-child(4) a:before {
          width: 60px;
        }

        i {
          display: none;
        }

        &:hover {
          &:nth-child(1)::before,
          &:nth-child(2)::before {
            @include width-height($width: 60px, $height: 2px);
          }
          &:nth-child(3)::before {
            @include width-height($width: 50px, $height: 2px);
          }
          &:nth-child(4)::before {
            @include width-height($width: 75px, $height: 2px);
          }
        }
      }
    }
  }
}

@include for-size(phone-only) {
  nav .navigation {
    .nav_menu .link_button {
      max-width: 55px;
    }

    .nav_button {
      background-color: #010b12;
      border-radius: 10px;
      transform: translateY(-12px);

      &.open {
        span {
          &:nth-child(1),
          &:nth-child(2) {
            top: 18px !important;
          }
          &:nth-child(5) {
            top: 38px !important;
          }
          &:nth-child(5),
          &:nth-child(6) {
            top: 2.1rem !important;
          }
        }

        ~ .nav_menu {
          left: 3.5rem;
        }
      }
    }

    .nav_menu {
      backdrop-filter: unset;
      background-color: #010b12;
    }
  }
}
