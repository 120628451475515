@import "../../../imports";

.download_button {
  @include flex($direction: row);
  @include border-outline($noBorder: true, $noOutline: true);
  background-color: transparent;
  @include font(
    $color: $moonSilver,
    $size: 18px,
    $weight: 300,
    $decoration: none
  );
  align-items: baseline;
  cursor: pointer;

  &:before {
    @include width-height($width: 115px, $height: 2px);
    @include transition($time-in: 0.25s);
    position: absolute;
    opacity: 0;
    margin-top: 2rem;
    margin-left: 5px;
    content: "";
    background-color: $peru;
  }

  &:hover {
    @include hoverBrighten();

    i {
      @include transition;
      @include transform(scale(1.2));
    }

    &:before {
      @include transition;
      opacity: 1;
    }
  }

  &.download a::before {
    width: 112px;
  }

  &.github a::before {
    width: 90px;
  }

  i {
    @include font($color: $moonSilver, $size: 24px);
    @include transition;
    @include transform(scale(1));
    padding: 0 0.5rem;
  }

  &.card_button {
    align-items: center;

    a {
      @include font(
        $size: 16px,
        $color: $moonSilver,
        $weight: 400,
        $align: left
      );
    }
  }
}
