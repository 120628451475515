@import "./styles/imports";

// animations
@import "./styles/components/animations/nightmode/campfire.scss";
@import "./styles/components/animations/nightmode/stars.scss";

// interactive components
@import "./styles/components/interactive/buttons/linkButton.scss";
@import "./styles/components/interactive/buttons/downloadButton.scss";

// layout components
@import "./styles/components/layout/header.scss";
@import "./styles/components/layout/navigation.scss";
@import "./styles/components/layout/wrapper.scss";
@import "./styles/components/layout/cards/aboutCard.scss";
@import "./styles/components/layout/cards/contentcard.scss";

// typography components
@import "./styles/components/typography/subtitle.scss";


// globals
body {
  overflow: auto;
}

h1 {
  @include font($size: 75px);
  &::selection {
    background: $fireBright;
    color: $offBlack;
  }
}

h2 {
  @include font($size: 60px);
}

h1,
h2,
h3,
h4 {
  @include font($tracking: 0.8px, $family: $heading);
}

h5,
h6,
p,
ul,
li,
span,
input,
input::placeholder,
textarea {
  @include font($family: $body);
}

.flex_row {
  @include flex(row);
}

.flex_column {
  @include flex(column);
}

@include for-size(phone-only) {
  h1 {
    @include font($size: 55px);
  }
}
