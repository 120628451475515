// base colors
$white: #fff;
$black: #000;
$offBlack: #181a18;

// color pallete
$peru: #da9e6e;
$maroon: #7c1414;
$maroonDark: #520e0e;
$moonSilver: #bdd6be;
$lightGreen: #aee298;
$dimGray: #678060;
$darkSlateGray: #354d2b;
$darkOliveGreen: #595440;
$darkCyan: #081f26;
$nightBlue: #031e25;

// fire colors
$fireBright: #ff7800;
$fireMedium: #f36200;
$fireDark: #d63603;
$fireShadow: #d43322;
$fireEmber: #ef5a00;
$log: linear-gradient(#e66465, $darkOliveGreen);

// rock colors
$rockDark: #2f484f;
$rockLight: #917472;

// font families
$heading: "Montserrat", sans-serif;
$body: "UniversLT57CondensedRegular", sans-serif;

// media breakpoints
$media-small-phones: 600px;
$media-tablet-portrait: 768px;
$media-tablet-landscape: 1024px;
$media-small-desktop: 1200px;
$media-large-desktop: 1600px;
