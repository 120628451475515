@import "../../../imports";

.link_button {
  @include flex($direction: row);
  @include border-outline($noBorder: true, $noOutline: true);
  background-color: transparent;

  a {
    @include flex($direction: row);
    @include font(
      $color: $moonSilver,
      $size: 18px,
      $weight: 300,
      $decoration: none
    );
    align-items: baseline;

    &::selection {
      background: $moonSilver;
      color: $offBlack;
    }

    &:before {
      @include width-height($width: 105px, $height: 2px);
      @include transition($time-in: 0.25s);
      position: absolute;
      opacity: 0;
      margin-top: 2rem;
      margin-left: 5px;
      content: "";
      background-color: $peru;
    }

    &:hover {
      @include hoverBrighten();

      i {
        @include transition;
        @include transform(scale(1.2));
      }

      &:before {
        @include transition;
        opacity: 1;
      }
    }
  }

  &.download a::before {
    width: 112px;
  }

  &.github a::before {
    width: 90px;
  }

  i {
    @include font($color: $moonSilver, $size: 24px);
    @include transition;
    @include transform(scale(1));
    padding: 0 0.5rem;
  }

  &.card_button {
    align-items: center;

    a {
      @include font(
        $size: 16px,
        $color: $moonSilver,
        $weight: 400,
        $align: left
      );
    }

    i.fa-github {
      font-size: 21px;
    }
    i.fa-external-link {
      font-size: 20px;
    }

    &.github a:hover::before {
      width: 80px;
    }
    &.app a:hover::before {
      width: 85px;
    }
    &.site a:hover::before {
      width: 95px;
    }
    &.link a:hover::before {
      width: 90px;
    }
  }
}
